@charset "UTF-8";

@font-face {
  font-family: "MuseoSans";
  src:url("./museosans-300-webfont.eot");
  src:url("./museosans-300-webfont.eot?#iefix") format("embedded-opentype"),
    url("./museosans-300-webfont.woff") format("woff"),
    url("./museosans-300-webfont.ttf") format("truetype"),
    url("./museosans-300-webfont.svg#pet-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSans";
  src:url("./museosans-500-webfont.eot");
  src:url("./museosans-500-webfont.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSans";
  src:url("./MuseoSans-700-webfont.otf");
  src:url("./MuseoSans-700-webfont.otf?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
    font-family: "MuseoSans";
    src:url("./museosans-900-webfont.eot");
    src:url("./museosans-900-webfont.eot?#iefix") format("embedded-opentype"),
      url("./museosans-900-webfont.woff") format("woff"),
      url("./museosans-900-webfont.ttf") format("truetype"),
      url("./museosans-900-webfont.svg#pet-icons") format("svg");
    font-weight: 900;
    font-style: normal;
}
