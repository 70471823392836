.container-LoginAAD {
    display: grid;
    width: 100%;  
    height: 100vh;
    background-image: url('https://idsvc.petronas.com/Images/img_background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#btn-login-aad {
    margin: auto;
    height: 50px;
    font-family: "Museo Sans 300",sans-serif;
    font-size: 13px !important;
    background-color: #fff !important;
    color: #00a19c !important;
    border: 1px solid #ddd;
    z-index: 999;
    font-weight: 600;
    border-radius: 5px;
}

#icon-LoginAAD {
    font-size: 30px !important;
    margin-right: 10px;
}