/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-12-19 14:51:44
 * @ Description: CSS for Profile component
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';

@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.ProfileContainer {
    position: relative;
    
    // width: calc(#{$topbar-avatar-size} + 2*#{$navbar-padding-x});
    width: 50px;
    // padding: calc((#{$topnavbar-height} - #{$topbar-avatar-size})/2) $navbar-padding-x;
    margin-right: -23px;
    z-index: 1;
    padding: 8px;
    z-index: 99;
    &:hover {
        background-color: var(--gray-850);
        .UserInfo {
            max-height: 30rem;
        }
    }
    .AvatarArea {       
        width: 2rem;
        height: 2rem;
        border-radius: 100px;
        background: #00a19c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .9rem;
        font-weight: 800;        
        overflow: hidden;
    }
    .AvatarImage{
        width: 100%;
        height: 100%;
    }
    .UserInfo {
        position: absolute;
        width: 18em;
        top: 100%;
        right: 0;
        background-color: var(--gray-850);
        /**Effect*/
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s linear;
        padding: 0 15px;

        .UserName{font-size:1em}
        .UserJobTitle{
            font-size: 0.8rem;
            color: #9a9898;
            margin:0;
        }
        .AvatarArea{
            margin: 20px auto;
            height: 4rem;
            width: 4rem;
            font-size: 1.4rem;
        }
        .ActionArea {
            border-top: 1px solid var(--gray-300);
            padding: 0.5rem 0;
            .ActionButton {
                line-height: 0;
                padding: 0.5rem;
                cursor: hand;
                cursor: pointer;
                border-radius: 50%;
                font-size: inherit;
                &:hover {
                    background-color: var(--gray-300);
                    color: var(--primary);
                }
                .Icon {
                    line-height: 0;
                    display: inline-block;
                }
            }
        }
    }
}