.page{
    &__header{
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 10px 0;
        background: #00a19c;
        justify-content: space-between;
        align-items: center;
    }
    &__title{
        font-size: 1.2rem;
        font-weight: 300;
        margin: 0;
        color: #fff
    }

    &__subtitle{
        font-size: 1.5rem;
    }
}

.modal-header{
    background: #00A19C;
    height: 40px;
}
.modal-title{
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0;
    color: #fff;    
}
.modal-content{
    border-radius: 6px;
}

.page-loading{
    position: fixed;
    left:0;
    right:0;
    bottom: 0;
    top: 0;
    background: #fff;
    z-index: 99999;

    &__outer{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
    }

    &__spinner {
        height: 100%;
        width: 100%;
        border: 3px solid transparent;
        border-radius: 50%;
        border-top: 4px solid #00A29C;
        animation: spin 4s linear infinite;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      &__logo{
        position: absolute;
        z-index: 0;
        width: 60px;
      }
      
      &__spinner::before,
      &__spinner::after {
        content: "";
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: 6px;
        right: 6px;
        border-radius: 50%;
        border: 4px solid transparent;
      }
      
      &__spinner::before {
        border-top-color: #00A29C;
        -webkit-animation: 3s spin linear infinite;
        animation: 3s spin linear infinite;
      }
      
      &__spinner::after {
        border-top-color: #00A29C;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
      }   
                   
      
    
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}   
        
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.table{
    thead{
        border: none;
        th{
            position: relative;
            padding: 4px 18px 4px 15px;
            font-size: 11px;
            font-weight: 500;
            color: black;
            height: 40px;
            -webkit-user-select: none;
            user-select: none;
            background-color: #fff;
            border: 0;
            vertical-align: middle;
            font-weight: bold;
            border:solid 1px #EEe;
            border-collapse: collapse;
        }
    }
    tbody{
        tr{
            border: thin solid #eaecee;
        }
        td{
            padding: 10px 18px;
            font-weight: 400;
            vertical-align: middle;
            font-size: 0.9em;
            border:0;
            max-width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border:solid 1px #EEe;
            border-collapse: collapse;
        }
    }
}

.counting-card{
    &__body{
        // display: flex;
        // align-items: stretch;
        padding: 0;
        overflow: hidden;
        justify-content: space-between;
    }
    &__title{
        // width: 30%;
        // display: flex;
        // align-items: center;
        padding: 1rem 1rem 0;
        font-size: 1.3rem;
        color: rgba(255, 255, 255, 0.5);
    }
    &__content{
        // min-width: 40%;
        // display: flex;
        // justify-content: center;
        padding: 0 1rem 1rem;
        // background-color: rgba(0, 0, 0, 0.08);
    }
    &__counter{
        font-size: 2rem;
    }

    &__icon{
        opacity: 0.5;
        position: absolute;
        right: 23px;
        font-size: 3rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

.panel-group{
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    &.no-border {
        border-radius: 0;
    }
    &__header{
      height: 35px;
      background: #e8e8e8;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 15px 15px;
    }
    &__title{
        font-size: 1rem;
        margin:0 ;
        color: #555555;
        font-weight: bold;
    }
    &__body{
      padding: 30px 30px;
    }
}

.page{
    &-error{
        text-align: center;
        margin-top: 12rem;

        &__title{
            font-size: 12rem;
            color: #81dcd9;
        }
    
        &__subtitle{
            font-size: 2rem;
        }
    
        &__content{
            margin-bottom: 50px;
        }
    }
}