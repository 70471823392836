/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-15 22:57:37
 * @ Description: Customize bootstrap modal.
 */

$modal-footer-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/modal';
@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.modal-header {
    @include flexbox() {
        align-items: center;
    }
    background-color: var(--secondary);
    color: var(--text-secondary);
    .modal-title {
        &.text-one-line {
            @extend %text-one-line;
        }
    }
    .close {
        @extend %modal-close;
        margin-right: -26px;
        color: var(--text-text-secondary);
        &::before {
            font-size: 1.1rem;
        }
        span {
            display: none;
        }
    }
}