@import './assets/fonts/museosan/museosan';
@import './assets/fonts/inter/inter';

@import '~bootstrap/scss/functions';
@import './assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/bootstrap.scss';

@import './assets/styles/scss/mixins';
@import './assets/styles/scss/utilities';
@import './assets/styles/scss/button';
@import './assets/styles/scss/modal';
@import './assets/styles/scss/forms';
@import './assets/styles/scss/_common';

html {
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1, .h1 {
  font-family: "MuseoSans", "Arial", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.required{
  color: red;
}

.react-datepicker-popper {
  z-index: 4 !important;
}