/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-12-19 14:47:30
 * @ Description: CSS for navbar
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.AppNav {
    align-items: unset !important;
    background-color: var(--gray-800);
    color: var(--gray-100);
    box-shadow: 0px 1px 2px 0px rgba($gray, 0.8);

    .NavBrand {
        display: inline-block;
        width: 49px;
        height: 50px;
        position: relative;
        margin: 0;
        padding: 0;
        margin-left: -40px;
        .LogoContainer {
            position: relative;
            width: 100%;
            padding-top: 100%;
            top: 0;
            // box-shadow: 0px 1px 1px 0px var(--gray-100);
            &::after {
                // content: '';
                // position: absolute;
                // bottom: 0;
                // right: calc(#{$topnavbar-height} - #{$logo-height});
                // border-left: calc(#{$logo-height} - #{$topnavbar-height}) solid var(--gray-300);
                // border-bottom: calc(#{$logo-height} - #{$topnavbar-height}) solid transparent;
            }
            .LogoImage {
                position: absolute;
                width: 30px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .AppTitle {
        position: relative;
        margin: 0 1px 0 0;
        .AppTitleLabel {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
        }
    }
    .NavbarToggler {
        border: none;
        outline: none;
        background-color: transparent;
        display: none;
        .NavIcon {
            width: $icon-size;
            height: 0.2rem;
            background-color: var(--gray-300);
            margin: 0.6rem auto;
        }
    }

    .MenuItemsContainer {
        .MenuItems {
            height: 100%;
            display: flex;
            align-items: center;
            
            .LinkItem {
                color: var(--gray-300);
                padding: 1.1rem;
                &:hover, &.active {
                    background: var(--gray-850);
                    color: var(--gray-100);
                }
            }
        }
    }
}