/*
 * @CreateTime: Oct 12, 2018 2:10 PM
 * @Author: Hieu Tran
 * @Contact: hieutranagi47@gmail.com
 * @Last Modified By: Hieu Tran
 * @Last Modified Time: Oct 12, 2018 2:11 PM
 * @Description: Added some scss variables to do this project.
 * The sequence continues with quaternary, quinary, senary, septenary, octonary, nonary, and denary
 */

 @import './function';

$primary-theme: (
    'primary':              #00A19C,
    'primary-50':           #E0F4F3,
    'primary-100':          #B3E3E1,
    'primary-300':          #4DBDBA,
    'primary-700':          #008F8A,
    'primary-900':          #00746E,
    'text-primary':         #F5F5F5,
    'text-primary-50':      #212121,
    'text-primary-100':     #212121,
    'text-primary-300':     #F5F5F5,
    'text-primary-700':     #F5F5F5,
    'text-primary-900':     #F5F5F5,
    'bg-color':             #F2F2F2,
    'text-color':           #212121
);

$purple-theme: (
    'secondary':            #763F98,
    'secondary-50':         #EFE8F3,
    'secondary-100':        #D6C5E0,
    'secondary-300':        #9F79B7,
    'secondary-700':        #633185,
    'secondary-900':        #461B6A,
    'text-secondary':       #F5F5F5,
    'text-secondary-50':    #212121,
    'text-secondary-100':   #212121,
    'text-secondary-300':   #212121,
    'text-secondary-700':   #F5F5F5,
    'text-secondary-900':   #F5F5F5
);

$blue-theme: (
    'secondary':            #20419A,
    'secondary-50':         #E4E8F3,
    'secondary-100':        #BCC6E1,
    'secondary-300':        #637AB8,
    'secondary-700':        #183288,
    'secondary-900':        #0B1C6C,
    'text-secondary':       #F5F5F5,
    'text-secondary-50':    #212121,
    'text-secondary-100':   #212121,
    'text-secondary-300':   #212121,
    'text-secondary-700':   #F5F5F5,
    'text-secondary-900':   #F5F5F5
);

$yellow-theme: (
    'secondary':            #FDB924,
    'secondary-50':         #FFF7E5,
    'secondary-100':        #FEEABD,
    'secondary-300':        #FECE66,
    'secondary-700':        #FCAA1B,
    'secondary-900':        #FC930D,
    'text-secondary':       #F5F5F5,
    'text-secondary-50':    #212121,
    'text-secondary-100':   #212121,
    'text-secondary-300':   #212121,
    'text-secondary-700':   #F5F5F5,
    'text-secondary-900':   #F5F5F5
);

$lime-green-theme: (
    'secondary':            #BFD730,
    'secondary-50':         #F7FAE6,
    'secondary-100':        #ECF3C1,
    'secondary-300':        #D2E36E,
    'secondary-700':        #B1CD24,
    'secondary-900':        #9BBE13,
    'text-secondary':       #F5F5F5,
    'text-secondary-50':    #212121,
    'text-secondary-100':   #212121,
    'text-secondary-300':   #212121,
    'text-secondary-700':   #F5F5F5,
    'text-secondary-900':   #F5F5F5
);

// Re-define bootstrap color:

$white:         #FFFFFF;
$white-smoke:   #F2F2F2;
$gray-50:       #FAFAFA;
$gray-100:      #F5F5F5;
$gray-300:      #E0E0E0;
$gray:          #9E9E9E;
$gray-700:      #616161;
$gray-800:      #414141;
$gray-850:      #313131;
$gray-900:      #212121;
$black:         #000000;

$light:         $gray-100;
$dark:          $gray-900;

$primary:       #00A19C;

$success:       #4CAF50;
$success-50:    #EAF5EA;
$success-100:   #C9E7CB;
$success-300:   #82C785;
$success-700:   #3C9F40;
$success-900:   #248627;

$danger:        #D50000;
$danger-50:     #FAE0E0;
$danger-100:    #F2B3B3;
$danger-300:    #E24D4D;
$danger-700:    #CA0000;
$danger-900:    #BA0000;

$warning:       #FDB924;
$warning-50:    #FFF7E5;
$warning-100:   #FEEABD;
$warning-300:   #FECE66;
$warning-700:   #FCAA1B;
$warning-900:   #FC930D;

$info:          #20419A;
$info-50:       #E4E8F3;
$info-100:      #BCC6E1;
$info-300:      #637AB8;
$info-700:      #183288;
$info-900:      #0B1C6C;

$alice-blue: #f3f4f5;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.05);
$black-10: rgba(0, 0, 0, 0.1);
$black-50: rgba(0, 0, 0, 0.5);
$black-60: rgba(0, 0, 0, 0.6);
$charcoal: #333436;
$cranberry: #9b151d;
$dark-aqua: #0c877a;
$davy-grey: #555555;
$dim-grey: #666666;
$flamingo-10: rgba(231, 85, 82, 0.1);
$gainsboro: #dcdcdc;
$grey: #666769;
$grey-chateau: #999a9c;
$jungle-mist: #b4b6b6;
$light-aqua: #ecf8f6;
$light-purple: #cfcade;
$light-silver: #d8d8d8;
$light-silver-20: rgba(216, 216, 216, 0.2);
$link-water1: #cccdcf;
$link-water2: #cdd3d9;
$night-rider: #333333;
$pale-grey: #f8f8f8;
$persian-green: #009e8e;
$snow: #fafafa;
$solitude: #ecedef;
$sugar-cane: #ebebe4;
$teal: #00a19c;
$white: #ffffff;
$white-0: rgba(255, 255, 255, 0);
$white-15: rgba(255, 255, 255, 0.15);
$white-smoke: #f4f4f4;
$white-smoke-60: rgba(248, 248, 248, 0.6);

$primary-font: 'museo_sans300';


$colorutilities: (
    "white":        $white,
    "white-smoke":  $white-smoke,
    "gray-50":      $gray-50,
    "gray-100":     $gray-100,
    "gray-300":     $gray-300,
    "gray":         $gray,
    "gray-700":     $gray-700,
    "gray-800":     $gray-800,
    "gray-850":     $gray-850,
    "gray-900":     $gray-900,
    "black":        $black,
    "success":      $success,
    "success-50":   $success-50,
    "success-100":  $success-100,
    "success-300":  $success-300,
    "success-700":  $success-700,
    "success-900":  $success-900,
    "danger":       $danger,
    "danger-50":    $danger-50,
    "danger-100":   $danger-100,
    "danger-300":   $danger-300,
    "danger-700":   $danger-700,
    "danger-900":   $danger-900,
    "warning":      $warning,
    "warning-50":   $warning-50,
    "warning-100":  $warning-100,
    "warning-300":  $warning-300,
    "warning-700":  $warning-700,
    "warning-900":  $warning-900,
    "info":         $info,
    "info-50":      $info-50,
    "info-100":     $info-100,
    "info-300":     $info-300,
    "info-700":     $info-700,
    "info-900":     $info-900,
);

// Background-image
$gradient-primary:  linear-gradient(153deg, #00A19C 5%, #028EA0 25%, #3F749F 51%, #763F98 82%, #703F98 100%);
$gradient-green:    linear-gradient(160deg, #00A19C 4%, #01929F 46%, #054C88 100%);
$gradient-purple:   linear-gradient(145deg, #AE73C9 0%, #20419A 100%);
$gradient-blue:     linear-gradient(142deg, #20419A 16%, #00A19C 100%);

/* BEGIN: CUSTOM BOOTSTRAP */

// Config for body tag in bootstrap.
$font-size-base: 1.6rem;
$font-family-base: "Inter", "Arial", "Helvetica Neue", sans-serif;
$font-weight-light:           100;
$font-weight-normal:          400;
$font-weight-bold:            700;
$body-color: var(--text-color);
$body-bg: var(--bg-color);

/* Buton in Bootstrap */
$btn-border-radius: 0;

// Add more color to theme beside primary, danger, warning, ...
$defaulttheme: $primary-theme;
// $defaulttheme: map-merge($primary-theme, $purple-theme);

/*********************************************************/
/** BEGIN: Added bootstrap customize for multiple themes */
// Purple theme
$themepurple: convert-theme('purple', $purple-theme);
$defaulttheme: map-merge($defaulttheme, $themepurple);

// Blue theme
$themeblue: convert-theme('blue', $blue-theme);
$defaulttheme: map-merge($defaulttheme, $themeblue);
/** END: Added bootstrap customize for multiple themes */
/*******************************************************/
$theme-colors: map-merge($map1: $colorutilities, $map2: $defaulttheme);

// Because $grid-breakpoints need order so they don't merge list
$grid-breakpoints: (
    s320:   0,
    s360:   323px,
    xs:     365px,
    sm:     576px,
    md:     768px,
    lg:     992px,
    xl:     1200px,
    xxl:    1440px,
    xxxl:   1600px,
    fhd:    1920px,
    2k:     2460px,
    4k:     4096px
);

// Because $container-max-width need order so they don't merge list
$container-max-widths: (
    sm:     540px,
    md:     720px,
    lg:     960px,
    xl:     1140px,
    xxl:    1366px,
    xxxl:   1440px,
    fhd:    1600px,
    2k:     1920px,
    4k:     3072px
);

/* END: CUSTOM BOOTSTRAP */

$padding-rl-standard:   1.2rem;
$padding-tb-standard:   0.8rem;
$item-margin:           1.2rem;
$icon-size:             1.4rem;

$topnavbar-height:      4.6rem;
$topbar-avatar-size:    3.4rem;
$logo-height:           5.6rem;
$navbar-padding-y:      0;
$navbar-padding-x:      2.4rem;

$left-aside-width:      4.6rem;

$footerbar-height:      2.2rem;
$footer-border-bottom:  0.2rem;

$backdrop-index: 1070;