@charset "UTF-8";

@font-face {
  font-family: "pet-icons";
  src:url("fonts/pet-icons.eot");
  src:url("fonts/pet-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/pet-icons.woff") format("woff"),
    url("fonts/pet-icons.ttf") format("truetype"),
    url("fonts/pet-icons.svg#pet-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "pet-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-pet-i-"]:before,
[class*=" icon-pet-i-"]:before {
  font-family: "pet-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pet-i-add:before {
  content: "\a00";
}
.icon-pet-i-wifi-off:before {
  content: "\62";
}
.icon-pet-i-save:before {
  content: "\63";
}
.icon-pet-i-user-circle-filled:before {
  content: "\64";
}
.icon-pet-i-check:before {
  content: "\65";
}
.icon-pet-i-calendar:before {
  content: "\66";
}
.icon-pet-i-background:before {
  content: "\67";
}
.icon-pet-i-back:before {
  content: "\68";
}
.icon-pet-i-draft:before {
  content: "\69";
}
.icon-pet-i-down:before {
  content: "\6a";
}
.icon-pet-i-doc:before {
  content: "\6b";
}
.icon-pet-i-delete:before {
  content: "\6c";
}
.icon-pet-i-currency:before {
  content: "\6d";
}
.icon-pet-i-copy:before {
  content: "\6e";
}
.icon-pet-i-color:before {
  content: "\6f";
}
.icon-pet-i-add-circle:before {
  content: "\71";
}
.icon-pet-i-clear:before {
  content: "\72";
}
.icon-pet-i-female-filled:before {
  content: "\73";
}
.icon-pet-i-files:before {
  content: "\74";
}
.icon-pet-i-move-file:before {
  content: "\75";
}
.icon-pet-i-folder:before {
  content: "\76";
}
.icon-pet-i-tile:before {
  content: "\77";
}
.icon-pet-i-up:before {
  content: "\78";
}
.icon-pet-i-move-lines:before {
  content: "\79";
}
.icon-pet-i-forward:before {
  content: "\7a";
}
.icon-pet-i-history:before {
  content: "\41";
}
.icon-pet-i-print:before {
  content: "\42";
}
.icon-pet-i-upload:before {
  content: "\43";
}
.icon-pet-i-user:before {
  content: "\44";
}
.icon-pet-i-quote:before {
  content: "\45";
}
.icon-pet-i-icon-placeholder:before {
  content: "\46";
}
.icon-pet-i-info:before {
  content: "\47";
}
.icon-pet-i-reload:before {
  content: "\48";
}
.icon-pet-i-user-circle:before {
  content: "\49";
}
.icon-pet-i-tag:before {
  content: "\4a";
}
.icon-pet-i-success:before {
  content: "\4b";
}
.icon-pet-i-sort:before {
  content: "\4c";
}
.icon-pet-i-male-filled:before {
  content: "\4d";
}
.icon-pet-i-menu:before {
  content: "\4e";
}
.icon-pet-i-female:before {
  content: "\4f";
}
.icon-pet-i-move:before {
  content: "\50";
}
.icon-pet-i-add-folder:before {
  content: "\51";
}
.icon-pet-i-add-menu:before {
  content: "\52";
}
.icon-pet-i-add-people:before {
  content: "\53";
}
.icon-pet-i-arrow-left:before {
  content: "\54";
}
.icon-pet-i-arrow-right:before {
  content: "\55";
}
.icon-pet-i-attachment:before {
  content: "\56";
}
.icon-pet-i-info-filled:before {
  content: "\57";
}
.icon-pet-i-drop:before {
  content: "\58";
}
.icon-pet-i-duplicate:before {
  content: "\59";
}
.icon-pet-i-list:before {
  content: "\5a";
}
.icon-pet-i-link:before {
  content: "\30";
}
.icon-pet-i-search:before {
  content: "\31";
}
.icon-pet-i-setting:before {
  content: "\32";
}
.icon-pet-i-share:before {
  content: "\33";
}
.icon-pet-i-share-folder:before {
  content: "\34";
}
.icon-pet-i-male:before {
  content: "\35";
}
.icon-pet-i-edit:before {
  content: "\37";
}
.icon-pet-i-expand:before {
  content: "\38";
}
.icon-pet-i-broken-link:before {
  content: "\39";
}
.icon-pet-i-send:before {
  content: "\21";
}
.icon-pet-i-petrol6:before {
  content: "\22";
}
.icon-pet-i-petrol5:before {
  content: "\23";
}
.icon-pet-i-petrol4:before {
  content: "\24";
}
.icon-pet-i-petrol1:before {
  content: "\25";
}
.icon-pet-i-petrol2:before {
  content: "\26";
}
.icon-pet-i-petrol3:before {
  content: "\27";
}
.icon-pet-i-i-klcc:before {
  content: "\28";
}
.icon-pet-i-i-home:before {
  content: "\29";
}
.icon-pet-i-create:before {
  content: "\2a";
}
.icon-pet-i-klcc-filled:before {
  content: "\2b";
}
.icon-pet-i-comment:before {
  content: "\2c";
}
.icon-pet-i-pass-hide:before {
  content: "\2d";
}
.icon-pet-i-id:before {
  content: "\2e";
}
.icon-pet-i-pass-show:before {
  content: "\2f";
}
.icon-pet-i-password:before {
  content: "\3a";
}
.icon-pet-i-open-windows:before {
  content: "\3b";
}
.icon-pet-i-exchange:before {
  content: "\3c";
}
.icon-pet-i-oil-drop:before {
  content: "\3d";
}
.icon-pet-i-bookmark1:before {
  content: "\3e";
}
.icon-pet-i-logo:before {
  content: "\3f";
}
.icon-pet-i-website:before {
  content: "\40";
}
.icon-pet-i-open-windows-1:before {
  content: "\5b";
}
.icon-pet-i-phone:before {
  content: "\5d";
}
.icon-pet-i-email:before {
  content: "\5e";
}
.icon-pet-i-heart:before {
  content: "\5f";
}
.icon-pet-i-bookmark2:before {
  content: "\60";
}
.icon-pet-i-bell:before {
  content: "\7b";
}
.icon-pet-i-library:before {
  content: "\7c";
}
.icon-pet-i-l-story:before {
  content: "\7d";
}
.icon-pet-i-l-science:before {
  content: "\7e";
}
.icon-pet-i-l-idea:before {
  content: "\5c";
}
.icon-pet-i-l-history:before {
  content: "\e000";
}
.icon-pet-i-l-forms:before {
  content: "\e001";
}
.icon-pet-i-l-ebook:before {
  content: "\e002";
}
.icon-pet-i-l-astronomy:before {
  content: "\e003";
}
.icon-pet-i-table:before {
  content: "\e004";
}
.icon-pet-i-categories:before {
  content: "\e005";
}
.icon-pet-i-cards:before {
  content: "\e006";
}
.icon-pet-i-archive:before {
  content: "\e007";
}
.icon-pet-i-attachment-88:before {
  content: "\e008";
}
.icon-pet-i-collections:before {
  content: "\e009";
}
.icon-pet-i-added-circle:before {
  content: "\36";
}
.icon-pet-i-added-people:before {
  content: "\e00a";
}
.icon-pet-i-arrow-tree:before {
  content: "\e00b";
}
.icon-pet-i-documents:before {
  content: "\e00c";
}
.icon-pet-i-explore:before {
  content: "\e00d";
}
.icon-pet-i-lock:before {
  content: "\e00e";
}
.icon-pet-i-logout:before {
  content: "\e00f";
}
.icon-pet-i-user-documents:before {
  content: "\e011";
}
.icon-pet-i-user-libraries:before {
  content: "\e012";
}
.icon-pet-i-users:before {
  content: "\e013";
}
.icon-pet-i-sort-table:before {
  content: "\e014";
}
.icon-pet-i-preview:before {
  content: "\e015";
}
.icon-pet-i-lock-disabled:before {
  content: "\e016";
}
.icon-pet-i-menu-lines:before {
  content: "\e017";
}
.icon-pet-i-unlock:before {
  content: "\e018";
}
.icon-pet-i-delegation:before {
  content: "\61";
}
.icon-pet-i-inbox:before {
  content: "\e019";
}
.icon-pet-i-draft-memo:before {
  content: "\e01a";
}
.icon-pet-i-pdf:before {
  content: "\e01b";
}
.icon-pet-i-request:before {
  content: "\e01c";
}
.icon-pet-i-replace:before {
  content: "\e01d";
}
.icon-pet-i-sent:before {
  content: "\e01e";
}
.icon-pet-i-trash:before {
  content: "\e01f";
}
.icon-pet-i-tree-collapse:before {
  content: "\70";
}
.icon-pet-i-tree-expand:before {
  content: "\e010";
}
.icon-pet-i-plus:before {
  content: "\e021";
}
.icon-pet-i-arrow-down:before {
  content: "\e022";
}
.icon-pet-i-arrow-up:before {
  content: "\e023";
}
.icon-pet-i-close:before {
  content: "\e024";
}
.icon-pet-i-minus:before {
  content: "\e020";
}