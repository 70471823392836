/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:28:26
 * @ Description: CSS for Left aside component.
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.LeftAsideContainer {

    .ToggleBtnArea {
        // padding-top: calc(#{$logo-height} - #{$topnavbar-height});
        // border-bottom: 1px solid var(--gray-300);
        .ToggleButton {
            line-height: 0;
            padding: 13px 12.5px;
            background-color: transparent;
            outline: none;
            border: none;
            color: var(--white);
            .ActionIcon {
                font-size: $icon-size;
                display: inline-block;
                line-height: 0;
                &::before {
                    display: inline-block;
                }
            }
        }
    }

    .ItemsList {
        list-style-type: none;
        margin-top: -7px;
        .Item {
            &:not(:last-child) {
                // border-bottom: thin solid #616161;
            }
            .ItemContainer {
                @extend %reset-a;
                font-size: 0;
                @include flexbox();
                position: relative;
                height: 55px;
                margin-bottom: 5px;
                
                &.active{
                    color: #fff;
                }
                &:hover, &.active {
                    background-color: #717171;
                    
                }
                .IconContainer {
                    @include flex(0 0 auto);
                    padding: 1rem 13px;
                    .Icon {
                        font-size: $icon-size;
                        line-height: 0;

                    }
                }
                .TextArea {
                    width: 0;
                    @include flex(0 0 0rem);
                    padding: 1rem 0rem;
                    transition: width 0.2s linear;
                    overflow: hidden;
                    &.ShowText {
                        @include media-breakpoint-up(lg) {
                            // width: 24rem;
                            @include flex(0 0 9rem);
                        }
                    }
                    .MenuTitle {
                        padding-right: 0.5rem;
                        @extend %text-one-line;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

}
