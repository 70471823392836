/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 13:43:41
 * @ Description: CSS for Layout component.
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.ContentContainer {
    @include flexbox();
    // height: calc(100vh - 50px);
    // height: calc(100vh - #{$topnavbar-height});
    // overflow: hidden;
    .Main{
        // min-height: calc(100vh);
        overflow: hidden;
    }
    .LeftAsideModule {
        @include flex(0 0 auto);        
        color: var(--white);        
        width: 48px;
        position: relative;
    }
    .RightAsideModule {       
        padding-top: 15px;
        overflow: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;      
        width: 100%;  
        @include scrollbar($gray-700, $gray-300, 8px, 0px);
        .Main {
            // height: 100%;
            // min-height: calc(100% - 2 * #{$footerbar-height} + #{$footer-border-bottom});
            @include media-breakpoint-up(lg) {
                // min-height: calc(100% - #{$footerbar-height});
            }
        }
        .LayoutInner{
            background: #fff;
            margin-bottom: 16px;
            box-shadow: 0 0 4px 1px #e2e2e2;
            min-height: calc(100vh - 81px);
        }        
    }
}