/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-15 22:57:59
 * @ Description: scss mixin.
 */

@import '~bootstrap/scss/functions';
// Replace defined variable in bootstrap
@import './variables';
@import '~bootstrap/scss/variables';

@mixin transition($attr...) {
    -webkit-transition: $attr;
    -moz-transition:    $attr;
    -ms-transition:     $attr;
    -o-transition:      $attr;
    transition:         $attr;
}

@mixin transform($attr...) {
    -webkit-transform: $attr;
    -moz-transform:    $attr;
    -ms-transform:     $attr;
    -o-transform:      $attr;
    transform:         $attr;
}

@mixin flexbox($important: false) {
    @if $important {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
    } @else {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    @content;
}

@mixin inline-flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    @content;
}

@mixin flex($values: 0 1 auto) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
    @content;
}

@mixin flex-calc($flexgrow: 0, $flexshrink: 1, $flexbasis: auto) {
    -webkit-flex-grow:  $flexgrow;
    -moz-flex-grow:     $flexgrow;
    flex-grow:          $flexgrow;
    -webkit-flex-shrink: $flexshrink;
    -moz-flex-shrink:   $flexshrink;
    flex-shrink:        $flexshrink;
    -webkit-flex-basis: $flexbasis;
    -moz-flex-basis:    $flexbasis;
    flex-basis:         $flexbasis;
    @content;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;  
    -moz-box-ordinal-group: $val;     
    -ms-flex-order: $val;     
    -webkit-order: $val;  
    order: $val;
}

@mixin filter($attr...) {
    -webkit-filter: $attr;
    -moz-filter:    $attr;
    -o-filter:      $attr;
    -ms-filter:     $attr;
    filter:         $attr;
}

@mixin prevent-select() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &>*, &>*::affter, &::before {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
}

@mixin bgcover($url: null) {
    @if ($url) {
        background-image: url($url);
    } @else {
        background-image: unset;
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin sticky-position {
    position: fixed;
    @supports (position: sticky) {
      position: sticky;
    }
}

@mixin placeholder($color, $fstyle: false) {
    &::-webkit-input-placeholder { /* Chrome */
        color: $color;
        transition: opacity 250ms ease-in-out;
        font-weight: 200;
        @if $fstyle {
            font-style: italic;
        }
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $color;
        transition: opacity 250ms ease-in-out;
        font-weight: 200;
        @if $fstyle {
            font-style: italic;
        }
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
        opacity: 1;
        transition: opacity 250ms ease-in-out;
        font-weight: 200;
        @if $fstyle {
            font-style: italic;
        }
    }
    &:-moz-placeholder { /* Firefox 4 - 18 */
        color: $color;
        opacity: 1;
        transition: opacity 250ms ease-in-out;
        font-weight: 200;
        @if $fstyle {
            font-style: italic;
        }
    }
    &:focus::-webkit-input-placeholder {
        opacity: 0.5;
    }
    &:focus:-ms-input-placeholder {
        opacity: 0.5;
    }
    &:focus::-moz-placeholder {
        opacity: 0.5;
    }
    &:focus:-moz-placeholder {
        opacity: 0.5;
    }
}

//http://mladenplavsic.github.io/css-ripple-effect @ modify by Hieu Tran
.ripple-effect {
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10,10);
        opacity: 0;
        transition: transform .4s, opacity .8s;
    }
    &:active:not(:disabled) {
        &:after {
            transform: scale(0,0);
            opacity: .3;
            transition: 0s;
        }
    }
}

@mixin ht-btn($Bgcolor, $color, $boderRadis: 0px, $borderColor: null, $padding: 10px 16px, $borderWidth: 2px, $fontSize: 16px) {
    background-color: $Bgcolor;
    color: $color;
    @if ($borderColor) {
        border: $borderWidth solid $borderColor;
    } @else {
        border: $borderWidth solid $Bgcolor;
    }
    font-size: $fontSize;
    font-weight: 700;
    line-height: 1;
    padding: $padding;
    margin-bottom: 10px;
    position: relative;
    cursor: hand;
    cursor: pointer;
    * {
        cursor: inherit;
    }
    border-radius: $boderRadis;
    min-width: 80px;
    @extend .ripple-effect;
    @content;
    &:hover {
        color: $color;
    }
    &:hover:not(:disabled) {
        background-color: darken($Bgcolor, 10) !important;
        color: $color !important;
        @if ($borderColor) {
            border-color: darken($borderColor, 10) !important;
        } @else {
            border-color: darken($Bgcolor, 10) !important;
        }
    }
    &:focus {
        color: $color;
    }
    &:active:not(:disabled) {
        background-color: darken($Bgcolor, 20) !important;
        @if ($borderColor) {
            border-color: darken($borderColor, 20) !important;
        } @else {
            border-color: darken($Bgcolor, 20) !important;
        }
        color: $color !important;
        box-shadow: unset;
    }
    &:disabled {
        opacity: .6;
        cursor: not-allowed;
    }
}

@mixin ht-btn-overlay($Bgcolor, $color, $boderRadis: 0px, $borderColor: null, $padding: 10px 16px, $borderWidth: 2px, $fontSize: 16px) {
    background-color: rgba($Bgcolor, .87);
    color: rgba($color: $color, $alpha: .87);
    @if ($borderColor) {
        border: $borderWidth solid rgba($borderColor, .27);
    } @else {
        border: $borderWidth solid rgba($Bgcolor, .27);
    }
    font-size: $fontSize;
    line-height: 1;
    padding: $padding;
    margin-bottom: 10px;
    position: relative;
    cursor: hand;
    cursor: pointer;
    border-radius: $boderRadis;
    min-width: 80px;
    @extend .ripple-effect;
    @content;
    &:hover {
        color: $color;
    }
    &:hover:not(:disabled) {
        background-color: darken($Bgcolor, 10) !important;
        color: $color !important;
        @if ($borderColor) {
            border-color: darken($borderColor, 10) !important;
        } @else {
            border-color: darken($Bgcolor, 10) !important;
        }
    }
    &:focus {
        color: $color;
    }
    &:active:not(:disabled) {
        background-color: darken($Bgcolor, 20) !important;
        @if ($borderColor) {
            border-color: darken($borderColor, 20) !important;
        } @else {
            border-color: darken($Bgcolor, 20) !important;
        }
        color: $color !important;
        box-shadow: unset;
    }
    &:disabled {
        opacity: .6;
        cursor: not-allowed;
    }
}

@keyframes btnEffect {
    from {background: radial-gradient(rgba(var(--white-rgb), .3) 8%, transparent 8.01%)}
    to {background: radial-gradient(rgba(var(--white-rgb), .3) 90%, transparent 90.01%)}
}

@mixin btn-close($size: 16px, $color: $white, $hoverCOlor: $red, $weight: 'light') {
    position: relative;
    line-height: $size;
    width: $size;
    height: $size;
    &::before {
        @if ($weight == 'bold') {
            content: "\274C";
        } @else if ($weight == 'normal') {
            content: "\2716";
        } @else {
            content: "\2715";
        }
        position: absolute;
        top: 0;
        left: 0;
        height: $size;
        width: $size;
        font-size: $size;
        color: $color;
        cursor: hand;
        cursor: pointer;
        padding: 2px 0 0 2px;
    }
    &:hover {
        &::before {
            color: $hoverCOlor;
        }
    }
    &:active {
        &::before {
            padding: 3px 0 0 3px;
        }
    }
}

@mixin scrollbar($color: $gray-700,
    $bgColor: $white-smoke,
    $barwidth: 6px,
    $borderRadius: 0,
    $hover2Display: null) {

    // Firefox 64+
    scrollbar-color: $color $bgColor;
    scrollbar-width: thin;

    scroll-behavior: smooth;
    scrollbar-face-color: $color;
    scrollbar-shadow-color: $color;
    scrollbar-highlight-color: $color;
    scrollbar-3dlight-color: $color;
    scrollbar-darkshadow-color: rgba($color, .47);
    scrollbar-track-color: $bgColor;
    scrollbar-arrow-color: $color;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @if ($barwidth == 0 or $barwidth == 0px) {
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }
    &::-webkit-scrollbar {
        width: $barwidth;
        height: $barwidth;
        background: $bgColor;
        @if ($hover2Display != null) {
            display: none;
        }
    }
    @if ($hover2Display != null) {
        &:hover {
            &::-webkit-scrollbar {
                display: inherit;
            }
        }
    }
    &::-webkit-scrollbar-button {
        display: none;
        height: $barwidth;
        width: $barwidth;
        background-color: $color;
    }
    &::-webkit-scrollbar-track {
        background-color: $bgColor;
    }
    &::-webkit-scrollbar-track-piece {
        background-color: $bgColor;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba($color: $color, $alpha: .87);
        border-radius: $borderRadius;
        -webkit-border-radius: $borderRadius;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($color: $color, $alpha: 1);
        border-radius: $borderRadius;
        -webkit-border-radius: $borderRadius;
    }
    &::-webkit-scrollbar-corner {
        background-color: $color;
    }
    &::-webkit-resizer {
        background-color: $bgColor;
        color: $color;
    }
}

@mixin input-range(
    $thumbColor: var(--primary),
    $thumbSize: 20px,
    $thumbRadius: 50%,
    $trackColor: #d3d3d3,
    $trackSize: 8px,
    $trackRadius: 10px) {

    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: 100%;
    &:focus {
        outline: none;
        &::-webkit-slider-runnable-track {
            background: $trackColor;
        }
        &::-ms-fill-lower {
            background: $trackColor;
        }
        &:focus::-ms-fill-upper {
            background: $trackColor;
        }
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: $trackSize;
        cursor: pointer;
        animation: 0.2s;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        background: $trackColor;
        border-radius: $trackRadius;
        border: 0px solid #000101;
    }
    &::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        height: $thumbSize;
        width: $thumbSize;
        border-radius: $thumbRadius;
        background: $thumbColor;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
    }
    //For IE 12+ -> Edge
    @supports (-ms-ime-align:auto) {
        &::-webkit-slider-thumb {
            margin-top: 0px !important;
        }
    }
    // FIREFOX
    &::-moz-range-track {
        width: 100%;
        height: $trackSize;
        cursor: pointer;
        animation: 0.2s;
        box-shadow: 0px 0px 0px transparent, 0px 0px 0px transparent;
        background: $trackColor;
        border-radius: $trackRadius;
        border: 0px solid transparent;
    }
    &::-moz-range-thumb {
        box-shadow: 0px 0px 0px transparent, 0px 0px 0px transparent;
        border: 0px solid transparent;
        height: $thumbSize;
        width: $thumbSize;
        border-radius: $thumbRadius;
        background: $thumbColor;
        cursor: pointer;
    }
    // IE
    &::-ms-track {
        height: $trackSize;
        padding: 0;
        cursor: pointer;
        animation: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: $trackColor;
        border: 0px solid #000101;
        border-radius: $trackRadius;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    &::-ms-fill-upper {
        background: $trackColor;
        border: 0px solid #000101;
        border-radius: $trackRadius;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    &::-ms-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        height: $thumbSize;
        width: $thumbSize;
        border-radius: $thumbRadius;
        background: $thumbColor;
        cursor: pointer;
    }
}

@mixin verticalCenter($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin btn-icon($color: $emerald-green, $bgcolor: $white, $lighter: true) {
    @extend .ripple-effect;
    background-color: transparent;
    color: $color;
    border: none;
    border-radius: 50%;
    @if $bgcolor != transparent {
        &:focus, &:hover {
            background-color: rgba($color: $bgcolor, $alpha: .3);
        }
    }
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 20px;
    &.btn-md {
        padding: 6px 12px;
    }
    &.btn-sm {
        padding: 3px 10px;
    }
    &[disabled="disabled"] {
        cursor: not-allowed;
        @if $lighter == true {
            color: lighten($color: $color, $amount: 5);
        }
        @else {
            color: darken($color: $color, $amount: 5);
        }
        * {
            cursor: inherit;
            @if $lighter == true {
                color: lighten($color: $color, $amount: 5);
            }
            @else {
                color: darken($color: $color, $amount: 5);
            }
        }
    }
}

@mixin btn-icon-round($color: $emerald-green, $bgcolor: $white, $lighter: true) {
    @extend .ripple-effect;
    background-color: $bgcolor;
    color: rgba($color, 0.8);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 20px;
    cursor: hand;
    cursor: pointer;
    &.btn-md {
        padding: 6px 12px;
    }
    &.btn-sm {
        padding: 3px 10px;
    }
    &:hover, &:active {
        color: $color;
        background-color: $white;
    }
    &[disabled="disabled"] {
        cursor: not-allowed;
        @if $lighter == true {
            color: lighten($color: $color, $amount: 20);
        }
        @else {
            color: darken($color: $color, $amount: 5);
        }
        * {
            cursor: inherit;
            @if $lighter == true {
                color: lighten($color: $color, $amount: 20);
            }
            @else {
                color: darken($color: $color, $amount: 5);
            }
        }
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content; 
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    } 
}

@mixin roltateY($deg) {
    -webkit-transform: rotateY($deg);
    -moz-transform: rotateY($deg);
    transform: rotateY($deg);
}

@mixin landscape() {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin portrait() {
    @media (orientation: portrait) {
        @content;
    }
}
