/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:31:58
 * @ Description: CSS for Backdrop component.
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/mixins/breakpoints";
@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

.BackdropContainer {
    position: fixed;
    z-index: $backdrop-index;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($black, 0.61);
}