/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-18 11:35:12
 * @ Description: CSS for Menubar on mobile.
 */

@import '~bootstrap/scss/functions';
@import './src/assets/styles/scss/variables';
@import '~bootstrap/scss/variables';

@import './src/assets/styles/scss/mixins';
@import './src/assets/styles/scss/utilities';

%menu-size {
    width: 80%;
    max-width: 32rem;
    height: 100vh;
}

.SidebarContainer {
    position: fixed;
    top: 0;
    left: 0;
    @extend %menu-size;
    @include flexbox() {
        flex-flow: column nowrap;
    }
    background-color: var(--bg-color);
    z-index: $backdrop-index + 1;
    transform: translateX(-100%);
    transition: transform 0.3s linear;
    &.ShowMe {
        transform: translateX(0);
    }

    .NavBar {
        @include flex(0 0 auto);
        .NavItem {
            background-color: var(--primary-100);
            border-bottom: 1px solid var(--white);
            color: inherit;
            &.NavBrand {
                background: var(--primary);
                height: $topnavbar-height;
                .NavbarBrand {
                    width: $topnavbar-height;
                    padding: 0.5rem;
                    margin-right: 2.4rem;
                    display: inline-block;
                    .AppLogo {
                        width: 100%;
                    }
                }
                .AppTitle {
                    color: var(--text-primary);
                }
            }
        }
    }

    .MenuListItemContainer {
        @include flex(1 1 auto);
        overflow: hidden;
        @include flexbox() {
            flex-flow: column nowrap;
        }

        @include landscape() {
            overflow-y: auto;
            @include scrollbar();
        }

        @include portrait() {
            .NavBarItems {
                overflow-y: auto;
                @include scrollbar();
            }
        }

        .NavBarItems {
            @include flex(1 1 auto);
            .NavItem {
                background-color: var(--primary-100);
                color: var(--text-primary-100);
                border-bottom: 1px solid var(--white);
                .NavLink {
                    color: inherit;
                    @include flexbox() {
                        align-items: center;
                    }
                    .Icon {
                        padding: 0.5rem;
                        display: inline-block;
                        line-height: 0;
                    }
                }
                &.Active, &:hover {
                    background-color: var(--primary-300);
                    color: var(--text-primary-300);
                }

                .ChildrenMenu {
                    position: fixed;
                    top: 0;
                    left: 0;
                    @extend %menu-size;
                    width: 100%;
                    background-color: var(--bg-color);
                    transform: translateX(-100%);
                    transition: transform 0.2s linear;
                    &.ShowMe {
                        transform: translateX(0);
                    }
                    .ChildrenHeader {
                        background-color: var(--primary);
                        color: var(--text-primary);
                        height: $topnavbar-height;
                        @include flexbox();
                        overflow: hidden;
                        .MenuTitle {
                            @include flex(1 1 auto);
                            @include flexbox() {
                                align-items: center;
                            }
                            overflow: hidden;
                            .HeaderTitle {
                                @extend %text-one-line;
                                @include flex(1 1 auto);
                            }
                            .HeaderIcon {
                                display: inline-block;
                                line-height: 0;
                                padding: 0.5rem;
                            }
                        }
                        .CloseBtn {
                            @extend %modal-close;
                            width: 3rem;
                            height: 3rem;
                            background-color: transparent;
                            cursor: pointer;
                            color: var(--text-primary);
                            top: 50%;
                            transform: translateY(-50%);
                            margin-right: 1rem;
                            @include flex(0 0 auto);
                            &::before {
                                font-size: 2.2rem;
                            }
                        }
                    }
                }
            }
        }
    
        .SiteConfig {
            @include flex(0 0 auto);
            padding: 2rem 0;
            margin-bottom: 0;
        }
    }
}