.form-fields{
  .row{
    margin-bottom: 30px;
    font-size: 15px;
  }
  .form-control{
    font-size: 12px;
  }
  textarea.form-control{
    height: 100px;
    resize: none;
  }
  .form-label{
    padding-top: 4px;
  }
}

.form-control{
  height: 29px;
  font-size: 12px;
}