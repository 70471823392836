/*
 * @CreateTime: Oct 12, 2018 2:10 PM
 * @Author: Hieu Tran
 * @Contact: hieutranagi47@gmail.com
 * @Last Modified By: Hieu Tran
 * @Last Modified Time: Oct 12, 2018 2:12 PM
 * @Description: Added some utilities to do this project.
 */
@import './variables';
@import './mixins';
 
//Because can not customize mdb, so we have no way to add more color on it.
 
 
%prevent-blink-flickering {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0) scale(1.0, 1.0);
}
 
%modal-close {
    height: 4rem;
    width: 4rem;
    position: relative;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    border-radius: 50%;
    color: var(--danger);
    &::before {
        content: "\2715";
        position: absolute !important;
        color: inherit;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 3rem;
        line-height: 1;
        transition: transform 0.3s linear;
    }
    &:hover {
        background-color: var(--light-gray);
        &::before {
            transform: translateX(-50%) translateY(-50%) scale(0.6);
        }
    }
}
 
%text-one-line {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%reset-a {
    text-decoration: none;
    color: inherit;
    cursor: hand;
    cursor: pointer;
}


.text-muted{
    color: #dcdcdc !important;
}