/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 10:23:59
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-15 10:35:28
 * @ Description: customize bootstrap button
 */

@mixin rounded-button($amount: 0rem) {
    border-radius: $amount;
    padding-left: $amount;
    padding-right: $amount;
}

/** Button */
.btn-rounded {
    @include rounded-button(1.7rem);
    &.btn-sm {
        @include rounded-button(1.4rem);
    }
    &.btn-lg {
        @include rounded-button(2.1rem);
    }
}

.btn{
    font-size: 0.8rem;
    border-radius: 3px;    
    height: 29px;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-flex;
    align-items: center;
}